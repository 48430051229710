import { BlockLink } from ".."

function NotificationsOverlay() {
  const notifications = [
    {
      id: "thebattery",
    },
    { id: "pershing" },
    { id: "fooclub" },
  ]
  const hasNotifications = !!notifications.find((n) => !n.readAt)

  return (
    <div className="w-screen max-w-sm">
      <div className="mx-auto w-11/12 overflow-hidden rounded border border-gold-300 bg-white shadow-md sm:w-full">
        <div className="max-h-144 w-full divide-y divide-gold-300 overflow-y-auto overscroll-none rounded text-gray-900">
          {hasNotifications ? (
            <>
              {notifications.map((n) => (
                <Notification key={n.id} notification={n} />
              ))}
            </>
          ) : (
            <div className="p-10">
              <div className="mx-auto flex h-10 w-10 items-center justify-center rounded-full">
                ✅
              </div>
              <h3 className="mt-5 text-center">Great news</h3>
              <p className="mt-1 text-center">You're all caught up on notifications</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function Notification({ notification }) {
  return (
    <BlockLink href={`/inbox/${notification.id}`} className="px-4 py-8">
      Notification {notification.id}
    </BlockLink>
  )
}

export default NotificationsOverlay
