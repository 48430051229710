import { Dialog } from "@headlessui/react"
import * as React from "react"
import { Anchor } from "~/core/components"
import { CloseIcon } from "~/core/components/icons"
import WomanHoldingCardSVG from "~/marketing/assets/woman-holding-card.svg" // 309x593
import * as Routes from "~/routes"

function MobileOverlay({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} className="fixed inset-0 bg-gray-900">
      <img
        src={WomanHoldingCardSVG.src}
        className="absolute right-0 top-0 w-[400px] opacity-50"
        aria-hidden="true"
        alt="Woman holding card"
      />
      <button
        onClick={onClose}
        className="absolute top-0 right-0 px-8 py-7 text-white outline-none"
      >
        <span className="sr-only">Close menu</span>
        <CloseIcon className="h-6 w-6" />
      </button>
      <div className="absolute bottom-0 left-0 space-y-8 px-8 py-20">
        <p className="mt-4 font-serif text-2xl font-semibold text-white">
          <Anchor
            onClick={onClose}
            className="transition duration-300 hover:text-gray-500"
            href={Routes.homepage()}
          >
            Home
          </Anchor>
        </p>
        <p className="mt-4 font-serif text-2xl font-semibold text-white">
          <Anchor
            onClick={onClose}
            className="transition duration-300 hover:text-gray-500"
            href={Routes.about()}
          >
            What we build
          </Anchor>
        </p>
        <p className="mt-2 font-serif text-2xl font-semibold text-white">
          <Anchor
            onClick={onClose}
            className="transition duration-300 hover:text-gray-500"
            href={Routes.contact()}
          >
            Get in touch
          </Anchor>
        </p>
        <p className="mt-2 font-serif text-2xl font-semibold text-gold-900">
          <Anchor
            onClick={onClose}
            className="transition duration-300 hover:text-gold-500"
            href={Routes.login()}
          >
            Log in
          </Anchor>
        </p>
      </div>
    </Dialog>
  )
}

export default MobileOverlay
