import clsx from "clsx"
import Link from "next/link"

function Anchor({ href, replace = false, target, rel, id, className, children, ...other }) {
  if (!href) {
    return (
      <span id={id} className={className}>
        {children}
      </span>
    )
  }
  if (!target && href[0] != "/") {
    target = "_blank"
  }
  if (target == "_blank") {
    rel = process.env.NODE_ENV === "production" ? "noopener" : "noreferrer"
  }
  return (
    <Link
      href={href}
      replace={replace}
      className={clsx("focus-visible:outline-none", className)}
      target={target}
      rel={rel}
      {...other}
    >
      {children}
    </Link>
  )
}
export default Anchor
