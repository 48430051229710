import Anchor from "./Anchor"

const PhoneTo = ({ number, className, children }) => (
  <Anchor className={className} href={href(number)}>
    {children || <span className="whitespace-nowrap">{formatNumber(number)}</span>}
  </Anchor>
)

const href = (number) => `tel:${number}`
const formatNumber = (number) =>
  number.startsWith("+1")
    ? `(${number.substring(2, 5)}) ${number.substring(5, 8)}-${number.substring(8)}`
    : number

PhoneTo.href = href
export default PhoneTo
