import Head from "next/head"
import svgFavicon from "~/core/assets/favicon.svg"
import { useBodyClassName } from "~/core/hooks"
import Footer from "./layout/Footer"
import Header from "./layout/Header"
import MobileTabBar from "./layout/MobileTabBar"

const brandName = "The Battery"
const brandLogoDarkMode =
  "https://i.sonato.dev/DuoQ8h9t129B355YlZWt7t3KdrYuFksm6tYJCcnwV_c/fill/0/800/sm/1/czM6Ly9zb25hdG8tc3Bpa2Utc2VlZHMvY2x1YnMvdGhlYmF0dGVyeXNmL2xvZ29fbGlnaHQuc3Zn.svg"
const brandBackgroundColor = "#022b43"

function Layout({ children }) {
  useBodyClassName("bg-[#022b43]")
  return (
    <>
      <Head>
        <meta name="theme-color" content="#f6f6f3" /> {/* gold100 */}
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href={svgFavicon.src} type="image/svg+xml" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="mb-16 flex min-h-screen flex-col bg-gold-100 text-base text-gray-800 sm:mb-0">
        <header>
          <Header />
        </header>
        <main className="grow">{children}</main>
        <footer className="hidden sm:block">
          <Footer
            backgroundColor={brandBackgroundColor}
            brandName={brandName}
            brandLogoImageUrl={brandLogoDarkMode}
          />
        </footer>
      </div>
      <div className="fixed inset-x-0 bottom-0 h-16 sm:hidden">
        <MobileTabBar backgroundColor={brandBackgroundColor} />
      </div>
    </>
  )
}

export default Layout
