import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import * as React from "react"
import "~/core/assets/main.css"
import { Layout as MarketingLayout } from "~/marketing/components"
import { Layout as MemberLayout } from "~/member/components"

const layoutMap = {
  marketing: MarketingLayout,
  member: MemberLayout,
}

function App({ Component, pageProps }) {
  const { layout, state, ...props } = pageProps
  const Layout = layoutMap[layout] || React.Fragment
  const [queryClient] = React.useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={state}>
        <Layout>
          <Component {...props} />
        </Layout>
      </Hydrate>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  )
}

export default App
