import Head from "next/head"

/**
 * Image component. Adds preload link to header if not lazy loading.
 *
 * #### Props
 *
 *     src        // default image src
 *     alt        // alt text
 *     width      // used with height to define aspect ratio
 *     height     // used with width to define aspect ratio
 *     sizes      // intended display sizes, comma-separated list of: media-query + width
 *     srcSet     // possible image sources based on desired size
 *     preload    // if true, adds a preload header and marks the image for eager loading. Otherwise,
 *                // the image is lazy loaded when it gets near the viewport (default).
 *     className  // optional classes
 */
function Image({ src, alt = "", width, height, sizes, srcSet, preload = false, className }) {
  preload = preload && !src.startsWith("data:")
  return (
    <>
      {preload && (
        <Head>
          {srcSet ? (
            <link as="image" imageSizes={sizes} imageSrcSet={srcSet} rel="preload" />
          ) : (
            <link as="image" href={src} rel="preload" />
          )}
        </Head>
      )}
      <img
        src={src}
        width={width}
        height={height}
        sizes={sizes}
        srcSet={srcSet}
        alt={alt}
        decoding="async"
        loading={preload ? "eager" : "lazy"}
        className={className}
      />
    </>
  )
}

export default Image
