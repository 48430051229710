import clsx from "clsx"
import { Anchor } from "~/core/components"

export default function BlockLink({ href, children, className }) {
  return (
    <Anchor href={href} className={clsx("block hover:bg-gray-100 hover:underline", className)}>
      {children}
    </Anchor>
  )
}
