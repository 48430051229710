/**
 * Convert first character in a string to uppercase, leaving the rest untouched.
 *
 * @example
 * capitalize("bob")
 * // "Bob"
 *
 * @param {string} string - word string to convert
 * @returns {string} capitalized string
 */
export const capitalize = (string) =>
  string !== "" ? string[0].toUpperCase() + string.slice(1) : ""

/**
 * Breaks a string or array into chunks of size `count`. The last chunk may contain less than
 * `count` items.
 *
 * @param {string|Array<*>} stringOrArray - a string or array
 * @param {number} count - split into chunks of this size.
 * @returns {Array<*>} chunked array
 */
export function chunkEvery(stringOrArray, count) {
  const result = []
  for (let i = 0; i < stringOrArray.length; i += count) {
    result.push(stringOrArray.slice(i, i + count))
  }
  return result
}

/**
 * Clamp a number between a min and max value.
 *
 * @param {number} number - number to clamp
 * @param {number} min - minimum value
 * @param {number} max - maximum value
 * @returns
 */
export const clamp = (number, min, max) => Math.min(Math.max(number, min), max)

/**
 * Pluralize / count something (in English).
 *
 * @param {number} count - number of things
 * @param {string} singular - singular word/phrase
 * @param {string} plural - plural word/phrase
 * @returns
 */
export const countable = (count, singular, plural) =>
  count === 1 ? `1 ${singular}` : `${count} ${plural}`

/**
 * Decode a base-64 string into a binary string.
 *
 * @example
 * decode64("YQ")
 * // "a"
 * decode64("YQ==")
 * // "a"
 *
 * @param {string} data - base-64 string to decode
 * @returns {string} decoded binary data
 */
export const decode64 = (data) =>
  typeof window === "undefined" ? Buffer.from(data, "base64").toString() : window.atob(data)

/**
 * Calculate the Euclidean distance between two points with cartesian coordinates.
 *
 * @example
 * distance(1, 2, 3, 4)
 * // 5
 *
 * @param {number} x1 - x coordinate of first point
 * @param {number} y1 - y coordinate of first point
 * @param {number} x2 - x coordinate of second point
 * @param {number} y2 - y coordinate of second point
 * @returns {number} Euclidean distance between the two points
 */
export const distance = (x1, y1, x2, y2) => Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)

/**
 * Encodes a string into a base-64 encoded string. No padding by default.
 *
 * @example
 * encode64("a")
 * // "YQ"
 * encode64("a", true)
 * // "YQ=="
 *
 * @param {string} data - binary string to encode
 * @param {boolean} padding - include padding, defaults to false
 * @returns {string} base-64 encoded string
 */
export function encode64(data, padding = false) {
  const encoded =
    typeof window === "undefined" ? Buffer.from(data).toString("base64") : window.btoa(data)
  return padding ? encoded : encoded.replace(/=+$/g, "")
}

/**
 * Encodes the given string to be RFC-3986 compliant. This is `encodeURIComponent` plus the percent
 * encoding for `!`, `'`, `(`, `)`, and `*`.
 *
 * @example
 * encodeRfc3986("interrobang?!")
 * // "interrobang%3F%21"
 *
 * @param {string} string - unencoded string
 * @returns {string} RFC-3986 encoded string
 */
export const encodeRfc3986 = (string) =>
  encodeURIComponent(string).replace(/[!'()*]/g, (c) => "%" + c.charCodeAt(0).toString(16))

/**
 * Encode a URL with optional query parameters. Handles the case if the `url` already constains some
 * pre-encoded query parameters.
 *
 * @example
 * encodeUrl("/search", { query: "foo" })
 * // "/search?query=foo"
 * encodeUrl("/search?limit=200", { query: "foo "})
 * // "/search?limit=200&query=foo"
 * encodeUrl("/none", {})
 * // "/none"
 *
 * @param {string} url - base URL
 * @param {Object} params - key/value parameters to be URL-encoded
 * @returns {string} URL string with properly encoded with parameters
 */
export function encodeUrl(url, params = {}) {
  const encodedParams = new URLSearchParams(params).toString()
  if (encodedParams == "") {
    return url
  }
  return url.includes("?") ? `${url}&${encodedParams}` : `${url}?${encodedParams}`
}

/**
 * Splits an array into groups based on `keyFn`.
 *
 * The result is an object where each key is given by `keyFn` and each value is an array of elements given by `valueFn`.
 * The order of elements within each list is preserved from the original array.
 *
 * @param {Array<*>} array - array to group
 * @param {Function} keyFn - key function (e.g. `(string) => string.length`)
 * @param {Function?} valueFn - optional value function (defaults to `(x) => x`)
 * @returns {Object} map
 */
export const groupBy = (array, keyFn, valueFn = (x) => x) =>
  array.reduce((acc, x) => ((acc[keyFn(x)] ||= []).push(valueFn(x)), acc), {})

/**
 * DJBX33A (Daniel J. Bernstein, Times 33 with Addition).
 * https://stackoverflow.com/questions/10696223/reason-for-5381-number-in-djb-hash-function/13809282#13809282
 *
 * @example
 * hashCode("abc")
 * // 108966
 *
 * @param {string} string - string to hash
 * @returns {number} integer (possibly negative)
 */
export const hashCode = (string) =>
  string.split("").reduce((a, b) => ((a << 5) + a + b.charCodeAt(0)) | 0, 0)

/**
 * Returns the hostname of the given URL or empty string.
 *
 * @example
 * hostname("https://example.com/foo")
 * // "example.com"
 *
 * @param {string} url - url string
 * @returns {string} hostname or empty string
 */
export const hostname = (url) => {
  try {
    return url ? new URL(url).hostname : ""
  } catch {
    return ""
  }
}

/**
 * An identity function that returns its first argument.
 *
 * @example
 * identity(123) // 123
 *
 * @param {*} value - input
 * @returns input value
 */
export const identity = (value) => value

/**
 * Invert an object's key/values. Values are coerced to strings as an object's key must be a string.
 * Duplicate values are overwritten by the latest value.
 *
 * @example
 * invertEntries({ a: "alpha", b: "beta" })
 * // { alpha: "a", beta: "b" }
 *
 * @param {Object} object - an object
 * @returns {Object} inverted object
 */
export const invertEntries = (object) =>
  Object.fromEntries(Object.entries(object).map((kv) => kv.reverse()))

/**
 * Computes the modulo remainder of a division. The result will always have the sign of the divisor.
 * This differs from the `%` operator in JavaScript, which is really the remainder operator.
 *
 * @example
 * mod(5, 2)
 * // 1
 * mod(-5, 2)  // note -5 % 2 is negative = -1
 * // 1
 *
 * @param {number} dividend - the dividend
 * @param {number} divisor - the divisor
 * @returns {number} integer with the sign of the divisor
 */
export const mod = (dividend, divisor) => {
  const remainder = dividend % divisor
  return remainder * divisor < 0 ? remainder + divisor : remainder
}

/**
 * A noop "no operation" function -- it ignores its inputs and always returns undefined.
 *
 * @example
 * <button onClick={noop}>Try again</button>
 *
 * @returns undefined
 */
export const noop = () => undefined

/**
 * Quantize a number to a given step interval.
 *
 * @example
 * quantize(31, 5)
 * // 30
 *
 * @param {number} value - value to quantize
 * @param {number} step - step interval
 * @returns {number} quantized value
 */
export const quantize = (value, step) => Math.round(value / step) * step

/**
 * Generate a random float between `min` (inclusive) and `max` (exclusive). If `max` is omitted,
 * generates a random float between `0-min`.
 *
 * @example
 * randomFloat(0, 99.99) // or randomFloat(99.99)
 * // 42.1
 *
 * @param {number} min - min value (non-negative, inclusive)
 * @param {number|undefined} max - max value (positive, exclusive), if undefined `0-min`
 * @returns {number} a random integer from `min` to `max-1`
 */
export const randomFloat = (min, max) =>
  max === undefined ? randomFloat(0, min) : min + Math.random() * (max - min)

/**
 * Generate a random integer between `min` (inclusive) and `max` (exclusive). If `max` is omitted,
 * generates a random integer between `0-min`.
 *
 * @example
 * randomInt(100) // or randomInt(0, 100)
 * // 42
 *
 * @param {number} min - min value (non-negative, inclusive)
 * @param {number|undefined} max - max value (positive, exclusive)
 * @returns {number} a random integer from `min` to `max-1`
 */
export const randomInt = (min, max) => Math.floor(randomFloat(min, max))

/**
 * Creates a new shuffled array via the Durtenfeld shuffle algorithm.
 *
 * @example
 * shuffleArray([1, 2, 3, 4, 5])
 * // [2, 4, 1, 5, 3]
 *
 * @param {Array<*>} input - input array
 * @returns {Array<*>} shuffled array
 */
export function shuffleArray(input) {
  const array = [...input]
  for (let i = array.length - 1; i > 0; i--) {
    const j = randomInt(0, i + 1)
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

/**
 * Sleep this duration.
 *
 * @example
 * await sleep(1000)
 *
 * @param {number} ms - milliseconds to sleep
 * @returns {Promise} a Promise that resolves in `ms` milliseconds
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * A stable array sort using a mapping function. This implements the Lisp decorate-sort-undecorate
 * pattern (aka Schwartzian transform): it first maps all the elements, then sorts by the mapped value,
 * and then returns an array of the original elements. If two elements map to the same value, the
 * original element order is preserved.
 *
 * @example
 * sortBy(data, (e) => parseISO(e.created_at), (a,b) => b - a)
 * // data sorted by created_at from latest to earliest
 *
 * @param {Array<*>} array - original array
 * @param {function} mapper - mapping function
 * @param {function|undefined} comparator - compare function, defaults to `a - b` if undefined
 * @returns {Array<*>} sorted array
 */
export const sortBy = (array, mapper, comparator = (a, b) => a - b) =>
  array
    .map((e, i) => [e, mapper(e), i])
    .sort((a, b) => {
      const result = comparator(a[1], b[1])
      return result == 0 ? a[2] - b[2] : result // if equal, use original element order
    })
    .map((e) => e[0])

/**
 * Convert a CSS object to a CSS value. Similar to what React does when using a style object in a Component.
 *
 * @example
 * styleToString({ backgroundColor: "#000", position: "absolute" })
 * // "background-color:#000;position:absolute"
 *
 * @param {Object} object - style object
 * @returns {string} style string
 */
export const styleToString = (object) =>
  Object.entries(object)
    .map(([k, v]) => {
      const kebab = k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)
      return `${kebab}:${v}`
    })
    .join(";")

/**
 * Return an array with the results of invoking the callback function with `i` where `0 <= i < n`.
 *
 * @example
 * times(2, "french hen") // ["french hen", "french hen"]
 * times(2, i => <div key={i}>{i}</div>) // JSX <div key="0">0</div><div key="1">1</div>
 * times(2) // [0, 1]
 *
 * @param {number} n - repeat this many times
 * @param {*|function|undefined} valueOrFunction - if undefined, uses the identity function
 * @returns {*[]} array of `n` elements, the result of each function call
 */
export function times(n, value = identity) {
  const array = Array(n)
  if (typeof value === "function") {
    return array.fill().map((_, index) => value(index))
  }
  return array.fill(value)
}

/**
 * Return a copy of the array removing all duplicated elements. The order of the elements is
 * preserved.
 *
 * @example
 * uniqArray(1, 2, 3, 4, 3, 2, 1)
 * // [1, 2, 3, 4]
 *
 * @param {*[]} array - an array
 * @returns {*[]} array without duplicates
 */
export const uniqArray = (array) => [...new Set(array)] // JS sets are iterable in insertion order!!

let uniqueCounter = randomInt(10000, 90000)
/**
 * Get a unique string identifier in this JavaScript environment.
 *
 * @example
 * uniqueId()
 * // "Z1"
 * uniqueId()
 * // "Z2"
 *
 * @params {string} [prefix] - optional prefix / tag for the identifier
 * @returns {string} a unique string
 */
export function uniqueId(prefix = "Z") {
  if (typeof window === "undefined") {
    prefix = `_${prefix}`
  }
  return `${prefix}${uniqueCounter++}`
}

/**
 * Removes common stop word determiners from the beginning of a name: the, a, an.
 *
 * This is typically done for a more natural sort order, for example "The Beatles" would be changed
 * to "Beatles" and sorted with other bands beginning with the letter B.
 *
 * @example
 * unprefixName("The Odor") // "Odor"
 * unprefixName("Theodore") // "Theodore"
 *
 * @param {string} name - a proper name
 * @returns {string} the unprefixed name (or original)
 */
export const unprefixName = (name) => name.replace(/^(the|a|an) /i, "")

/**
 * Decodes a URL-safe base-64 string into a binary string.
 *
 * @example
 * urlDecode64("QS9CK0M")
 * // "A/B+C"
 * urlDecode64("QS9CK0M=")
 * // "A/B+C"
 *
 * @param {string} data - URL-safe base-64 string to decode
 * @returns {string} decoded binary data
 */
export const urlDecode64 = (data) => decode64(data.replace(/-/g, "+").replace(/_/g, "/"))

/**
 * Encodes a binary string into a base-64 encoded string with a URL and filename safe alphabet.
 *
 * @example
 * urlEncode64("A/B+C")
 * // "QS9CK0M"
 * urlEncode64("A/B+C", true)
 * // "QS9CK0M="
 *
 * @param {string} data - binary string to encode
 * @param {boolean} padding - include padding, defaults to false
 * @returns {string} URL-safe base-64 encoded string
 */
export const urlEncode64 = (data, padding) =>
  encode64(data, padding).replace(/\+/g, "-").replace(/\//g, "_")
