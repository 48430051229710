import { parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

const FORMATTERS = {
  dashDayMonthYear: (date, timeZone) => formatInTimeZone(date, timeZone, "dd-MMM-yyyy"),
  dotYearMonthDay: (date, timeZone) => formatInTimeZone(date, timeZone, "yyyy.MM.dd"),
  inputValueDate: (date) => inputValueDate(date),
  inputValueDateTime: (date) => inputValueDateTime(date),
  inputValueTime: (date) => inputValueTime(date),
  iso8601: (date, timeZone) => formatInTimeZone(date, timeZone, "yyyy-MM-dd"),
  iso8601DateTimeUTC: (date) => date.toISOString(),
  locale: (date) => date.toLocaleString(),
  month: (date, timeZone) => formatInTimeZone(date, timeZone, "LLLL"),
  monthDayYear: (date, timeZone) => formatInTimeZone(date, timeZone, "MMMM d, yyyy"),
  monthYear: (date, timeZone) => formatInTimeZone(date, timeZone, "MMMM yyyy"),
  shortMonthDay: (date, timeZone) => formatInTimeZone(date, timeZone, "MMM d"),
  shortMonthDayYear: (date, timeZone) => formatInTimeZone(date, timeZone, "MMM d, yyyy"),
  shortMonthYear: (date, timeZone) => formatInTimeZone(date, timeZone, "MMM yyyy"),
  weekday: (date, timeZone) => formatInTimeZone(date, timeZone, "cccc"),
  weekdayMonthDayTimeZone: (date, timeZone) =>
    formatInTimeZone(date, timeZone, "ccc, MMM d, h:mmaaa (zzz)"),
  year: (date, timeZone) => formatInTimeZone(date, timeZone, "yyyy"),
}

/**
 * Available formats for `formatDate` function. Formatted for the given time zone (or current), unless
 * specified otherwise in the format.
 *
 * ```
 * "dashDayMonthYear"        // 02-Sep-2020 (nhs.uk)
 * "dotYearMonthDay"         // 2020.09.02
 * "inputValueDate"          // 2020-09-02 (in UTC)
 * "inputValueDateTime"      // 2020-09-02T12:34:56 (in UTC)
 * "inputValueTime"          // 12:34:56 (in UTC)
 * "iso8601"                 // 2020-09-02
 * "iso8601DateTimeUTC"      // 2020-09-02T12:34:56.789Z
 * "locale"                  // 4/12/2022, 9:42:45 AM (local-specific full date time)
 * "month"                   // September
 * "monthDayYear"            // September 2, 2020
 * "monthYear"               // September 2020
 * "shortMonthDay"           // Sep 2
 * "shortMonthDayYear"       // Sep 2, 2020
 * "shortMonthYear"          // Sep 2020
 * "weekday"                 // Wednesday
 * "weekdayMonthDayTimeZone" // Wed, Sep 2, 12:34pm (PST)
 * "year"                    // 2020
 * ```
 */
export const FORMATS = Object.keys(FORMATTERS)

/**
 * Format the given date (an ISO-8601 string, Unix timestamp in milliseconds, or Date object).
 *
 * @example
 * const d = new Date(1599050096000)
 * formatDate(d, "dashDayMonthYear")
 * // "02-Sep-2020"
 * formatDate(d, "monthDayYear")
 * // September 2, 2020
 *
 * @param {string|number|Date} date string, unix timestamp, or date
 * @param {string|function} format format string or formatter function
 * @param {string|undefined} timeZone IANA timezone (or local timezone if undefined)
 * @returns {string} formatted string or empty string if invalid
 */
export function formatDate(date, format, timeZone) {
  date = parseDate(date)
  let formatter = typeof format === "function" ? format : FORMATTERS[format]
  if (!formatter) {
    throw new Error("formatDate: invalid format specified")
  }
  return date ? formatter(date, timeZone) : ""
}

/**
 * Returns true if passed a valid date.
 *
 * @param {*} date object to check
 * @returns {boolean} true if a valid date
 */
export const isValidDate = (date) => date instanceof Date && !isNaN(date)

/**
 * Parses an ISO-8601 string or Unix timestamp (in milliseconds) into a Data object (or passes through
 * and existing Date object).
 *
 * @example
 * parseDate("2021-09-03T16:50:40.496Z")
 * // Date object: Fri Sep 03 2021 09:50:40 GMT-0700 (Pacific Daylight Time)
 *
 * @param {string|number|Date} date ISO-8601 string, Unix timestamp in milliseconds, or a Date
 * @returns {Date|null} Date object or null if invalid
 */
export function parseDate(date) {
  if (typeof date === "string") {
    date = parseISO(date)
  } else if (typeof date === "number") {
    date = new Date(date)
  }
  return isValidDate(date) ? date : null
}

// <input type="date" />
// yyyy-mm-dd (UTC)
const inputValueDate = (date) =>
  date.toLocaleString("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })

// <input type="datetime-local" />
// yyyy-mm-ddThh:mm:ss (UTC), see https://dev.to/mendyberger/input-and-js-dates-2lhc
const inputValueDateTime = (date) =>
  date
    .toLocaleString("sv-SE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(" ", "T")

// <input type="time" />
// hh:mm:ss (UTC)
const inputValueTime = (date) =>
  date.toLocaleString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
