import * as React from "react"
import { randomInt, styleToString } from "~/core/utils"

/**
 * Use this when you want `useLayoutEffect` in the browser without the Next.js SSR warning. This
 * should only be used when we need immediate access to the DOM or window and will then make changes
 * to the DOM or trigger a synchronous re-render to avoid an invalid UI state (flicker).
 */
export const useBrowserLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect

/**
 * Assign the given class string to the body element (overwriting any existing classes). Restores
 * original class string on unmount.
 *
 * @param {string} className - class string to assign to the body element
 */
export function useBodyClassName(className) {
  useBrowserLayoutEffect(() => {
    const original = document.body.className
    document.body.className = className
    return () => {
      document.body.className = original
    }
  }, [className])
}

/**
 * Sets body element style. Restores original styling on unmount.
 *
 * @param {Object} styleObject - a React / JSON style object with camelCase keys
 */
export function useBodyStyle(styleObject) {
  const style = styleToString(styleObject)
  useBrowserLayoutEffect(() => {
    const original = document.body.style
    document.body.style = style
    return () => {
      document.body.style = original
    }
  }, [style])
}

/**
 * A hook that executes a callback if a click occurs outside attached element ref.
 *
 * @param {Function} callback - callback handler
 * @returns {React.MutableRefObject} mutable ref to attach to DOM element
 */
export function useClickOutside(callback) {
  const elementRef = React.useRef()
  const callbackRef = React.useRef()
  callbackRef.current = callback

  React.useEffect(() => {
    function handleClickOutside(e) {
      // iff element is defined
      if (elementRef.current?.contains(e.target)) {
        callbackRef.current?.(e) // iff callback is defined
      }
    }
    document.addEventListener("click", handleClickOutside, { capture: true, passive: false })
    return () => document.removeEventListener("click", handleClickOutside, { capture: true })
  }, [])

  return elementRef
}

/**
 * Detect mobile / touch devices. These values do not change after mount.
 *
 * ```
 * isAndroid       true if Android
 * isIPad          true if iPad (typically use isIOS instead)
 * isIPhone        true if iPhone (typically use isIOS instead)
 * isIOS           true if iOS device
 * isMobileSafari  true if Mobile Safari
 * isTouch         true if touch device (course-input)
 * ```
 *
 * @returns {Object} device object (initially {})
 */
export function useDevice() {
  const [device, setDevice] = React.useState({})
  useBrowserLayoutEffect(() => {
    const ua = window.navigator.userAgent
    const isAndroid = /android/i.test(ua)
    const isIPad = /ipad/i.test(ua)
    const isIPhone = /iphone/i.test(ua)
    const isMobileSafari = /mobile.*safari/i.test(ua)
    const isTouch = window.matchMedia("(pointer:coarse)").matches
    setDevice({
      isAndroid,
      isIPad,
      isIPhone,
      isIOS: isIPad || isIPhone,
      isMobileSafari,
      isTouch,
    })
  }, [])
  return device
}

/**
 * Check system dark mode setting.
 *
 * @returns {boolean|undefined} undefined initially
 */
export const useDarkMode = () => useMediaQuery("(prefers-color-scheme:dark)")

/**
 * Returns a function that can be invoked to force an update / re-render.
 *
 * @returns {Function} stable force update function
 */
export const useForceUpdate = () => React.useReducer((x) => x + 1, 0)[1]

/**
 * Returns `true` after component has mounted on the client.
 *
 * @returns {boolean} true when mounted on client
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  useBrowserLayoutEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

/**
 * This hook sets `isHovered` if its `ref` is hovered. The `ref` should be attached to the element
 * you're interested in. An optional callback can be passed that is invoked on/off hover.
 *
 * @param {Function} callback - optional callback (first argument is `isHovered`)
 * @returns {Object} with `ref` and `isHovered` properties
 */
export function useHover(callback) {
  const ref = React.useRef()
  const callbackRef = React.useRef()
  callbackRef.current = callback
  const [isHovered, setIsHovered] = React.useState(false)

  React.useEffect(() => {
    const element = ref.current
    if (!element) {
      console.error("useHover: ref is not set")
      return
    }
    function pointerEnterHandler() {
      callbackRef.current?.(true)
      setIsHovered(true)
    }
    function pointerLeaveHandler() {
      callbackRef.current?.(false)
      setIsHovered(false)
    }
    element.addEventListener("pointerenter", pointerEnterHandler, { passive: true })
    element.addEventListener("pointerleave", pointerLeaveHandler, { passive: true })
    return () => {
      element.removeEventListener("pointerenter", pointerEnterHandler)
      element.removeEventListener("pointerleave", pointerLeaveHandler)
    }
  }, [])
  return { ref, isHovered }
}

/**
 * Periodially trigger a callback. Changing the callback function will not reset the interval.
 *
 * @param {Function} callback - callback function
 * @param {number} delay - invocation interval in milliseconds.
 */
export function useInterval(callback, delay) {
  const callbackRef = React.useRef()
  callbackRef.current = callback

  React.useEffect(() => {
    const tick = () => callbackRef.current?.()
    if (delay) {
      const intervalId = setInterval(tick, delay)
      return () => clearInterval(intervalId)
    }
  }, [delay])
}

/**
 * React useState hook that stores items in local storage. Values must be JSON serializable.
 * WARN: does not sync across instances with same key or across browser tabs. Prefer `jotai`.
 *
 * @param {string} key - key name
 * @param {*} defaultValue - default value (JSON serializable)
 * @returns {[*,Function]} like useState
 */
export function useLocalStorageState(key, defaultValue) {
  const [value, setValue] = React.useState()
  React.useEffect(() => {
    if (value === undefined) {
      const stored = localStorage.getItem(key)
      let newValue = defaultValue
      if (stored) {
        try {
          newValue = JSON.parse(stored)
        } catch {
          // treat parse error as non-existent value
        }
      }
      setValue(newValue)
      return
    }
    localStorage.setItem(key, JSON.stringify(value))
  }, [defaultValue, key, value])
  return [value, setValue]
}

/**
 * Check if the given media query matches, rerendering on change.
 *
 * @returns {boolean|undefined} undefined initially
 */
export function useMediaQuery(query) {
  const [match, setMatch] = React.useState()
  const onChange = ({ matches }) => setMatch(matches)
  useBrowserLayoutEffect(() => {
    const mql = window.matchMedia?.(query)
    if (mql) {
      onChange(mql)
      if (mql.addEventListener) {
        mql.addEventListener("change", onChange, { passive: true })
        return () => mql.removeEventListener("change", onChange)
      }
      if (mql.addListener) {
        // FIX: Safari <= 13 must use addListener/removeListener
        // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList
        mql.addListener(onChange)
        return () => mql.removeListener(onChange)
      }
    }
  }, [query])
  return match
}

/**
 * Block navigation gestures around the given element `ref` on touch devices. The reference element
 * should touch the edges of the screen. This ONLY works in iOS devices.
 *
 * Tested on 2022-08-05: iOS Safari, iOS Chrome, iOS Firefox, iOS Opera, iOS Edge
 * DOES NOT WORK on Android 12 with OS-level gesture navigations enabled.
 *
 * Options object
 *     enabled      enable gesture block (default true)
 *     startY       only block gestures below this Y position (default 66)
 *     endY         only block gustures above this Y position (default 1000)
 *     marginX      block if within this distance from edge (default 40)
 *     containerId  block gestures on this element (default "__next")
 *
 * @param {Object} options - options object
 */
export function useGestureNavigationBlock(options = {}) {
  const { startY = 66, endY = 1000, marginX = 40, containerId = "__next", enabled = true } = options
  useBrowserLayoutEffect(() => {
    if (!enabled || !window.matchMedia("(pointer:coarse)").matches) {
      return // skip if it's not a touch device
    }
    function handleTouchStart(e) {
      if (
        e.pageY >= startY &&
        e.pageY < endY &&
        (e.pageX < marginX || e.pageX >= window.innerWidth - marginX)
      ) {
        e.preventDefault()
      }
    }
    const container = document.getElementById(containerId)
    // must be touchstart (not pointerstart)
    container.addEventListener("touchstart", handleTouchStart, { passive: false })
    return () => container.removeEventListener("touchstart", handleTouchStart)
  }, [startY, endY, marginX, containerId, enabled])
}

/**
 * Determine current device orientation.
 *
 * ```
 * changedSinceMount  true iff orientation changed since mount
 * isLandscape        true if currently landscape (height < width)
 * isPortrait         true if currently portrait (height >= width)
 * ```
 *
 * @returns {Object} orientation object (values initially undefined)
 */
export function useOrientation() {
  const [initiallyPortrait, setInitiallyPortrait] = React.useState()
  const changedRef = React.useRef(false)
  const isPortrait = useMediaQuery("(orientation:portrait)")
  React.useEffect(() => {
    setInitiallyPortrait(window.matchMedia("(orientation:portrait)").matches)
  }, [])
  if (isPortrait === undefined) {
    return {}
  }
  if (!changedRef.current && isPortrait !== initiallyPortrait) {
    changedRef.current = true
  }
  return {
    changedSinceMount: changedRef.current,
    isLandscape: !isPortrait,
    isPortrait,
  }
}

/**
 * Check if the user prefers reduced (i.e. no) motion for animations.
 *
 * @returns {boolean|undefined} undefined initially
 */
export function usePrefersReducedMotion() {
  const motionOkay = useMediaQuery("(prefers-reduced-motion:no-preference)")
  return motionOkay === undefined ? undefined : !motionOkay
}

/**
 * Periodially trigger a callback. The a random delay is chosen between `minDelay` and `maxDelay` each
 * interation.
 *
 * @param {number} minDelay - minimum delay in miliiseconds (inclusive)
 * @param {number} maxDelay - maximum delay in milliseconds (exclusive).
 * @param {Function} callback - callback function
 */
export function useRandomInterval(minDelay, maxDelay, callback) {
  const timeoutId = React.useRef()
  const callbackRef = React.useRef()
  callbackRef.current = callback

  const schedule = React.useCallback(() => {
    const ms = randomInt(minDelay, maxDelay)
    timeoutId.current = setTimeout(() => {
      callbackRef.current?.()
      schedule()
    }, ms)
  }, [minDelay, maxDelay])

  React.useEffect(() => {
    schedule()
    return () => clearTimeout(timeoutId.current)
  }, [schedule])
}

/**
 * Hook to monitor the size of an element ref.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/DOMRectReadOnly
 *
 * @param {React.MutableRefObject} ref - element ref
 * @returns {DOMRect} - size and position of a rectangle
 */

export function useResizeObserver(ref) {
  const [rect, setRect] = React.useState({})
  useBrowserLayoutEffect(() => {
    const element = ref.current
    if (!element) {
      throw new Error("useResizeObserver: ref is not set")
    }
    const observer = new ResizeObserver((entries) => setRect(entries[0].contentRect))
    observer.observe(element)
    return () => observer.unobserve(element)
  }, [ref])
  return rect
}

/**
 * Schedule a callback to fire at the given time (if defined).
 *
 * @param {Function} callback - callback function
 * @param {Date|number|undefined} scheduleAt - time to schedule (a Date or unix time)
 */
export function useSchedule(callback, scheduleAt) {
  const timeoutId = React.useRef()
  const callbackRef = React.useRef()
  callbackRef.current = callback

  const schedule = React.useCallback(() => {
    if (scheduleAt) {
      timeoutId.current = setTimeout(() => callbackRef.current?.(), scheduleAt - Date.now())
    }
  }, [scheduleAt])

  React.useEffect(() => {
    schedule()
    return () => clearTimeout(timeoutId.current)
  }, [schedule])
}

/**
 * A simple `useState` wrapper that toggles between true/false (on/off).
 *
 * @param {boolean} initialState - initial state
 * @returns {[boolean,Function]} toggle state and toggler function
 */
export const useToggle = (initialState) => React.useReducer((previous) => !previous, initialState)

/**
 * Returns the current viewport's size as `width` and `height` in CSS pixels.
 *
 * Also sets the following minimum-width breakpoint booleans:
 *
 * ```text
 * mobile < 724px  mobile phones, typically >=375px
 * sm >= 724px     portrait tablet / landscape phone (iPhone 13 Mini / iPhone 11 Pro + safe area)
 * md >= 1024px    laptop / landscape tablet (iPad Mini 5th gen / iPad)
 * lg >= 1280px    desktop / landscape (iPad Pro 12.9")
 * xl >= 1536px    huge desktop / 4K
 * ```
 *
 * For exactly `sm`, use `breakpoint.sm && !breakpoint.md`. For special-cased in-between
 * breakpoints, use the width property directly.
 *
 * @returns {Object} object with `width` and `height` properties (undefined values initially)
 */
export function useViewport() {
  const [info, setInfo] = React.useState({})
  useBrowserLayoutEffect(() => {
    function handleResize() {
      const width = window.innerWidth
      const height = window.innerHeight
      const info = {
        width,
        height,
        mobile: width < 724,
        sm: width >= 724,
        md: width >= 1024,
        lg: width >= 1280,
        xl: width >= 1536,
      }
      setInfo(info)
    }
    handleResize()
    window.addEventListener("resize", handleResize, { passive: true })
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return info
}

/**
 * Monitors changes in visibility state.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 *
 * @returns {string} "visible" or "hidden"
 */
export function useVisibilityState() {
  const [visibilityState, setVisibilityState] = React.useState("visible")
  React.useEffect(() => {
    const handler = () => setVisibilityState(document.visibilityState)
    window.addEventListener("visibilitychange", handler, { passive: true })
    return () => window.removeEventListener("visibilitychange", handler)
  }, [])
  return visibilityState
}
