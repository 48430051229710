function domain() {
  if (typeof window === "undefined") {
    return process.env.DOMAIN
  }
  return window.location.hostname.split(".").splice(-2).join(".")
}

// pubic / marketing
export const about = () => "/about"
export const contact = () => "/contact"
export const homepage = () => "/"
export const login = (subdomain) => (subdomain ? `/login?subdomain=${subdomain}` : "/login")
export const privacyPolicy = () => "/privacy"
export const faq = () => "/faq"
export const termsOfUse = () => "/terms"
export const deleteAccount = () => "/delete-account"

// api
export const apiUrl = (path) => `https://api.${domain()}${path}`

// external
export const appStoreDownload = () => "https://apps.apple.com/us/app/sonato-alliance/id1553756187"
export const googlePlayDownload = () =>
  "https://play.google.com/store/apps/details?id=com.sonato.members"
export const signInRedirect = (subdomain) =>
  subdomain ? `https://auth.${domain()}/${subdomain}/sign-in` : `https://auth.${domain()}/sign-in`
export const memberSiteRedirect = (token, subdomain) =>
  `https://${subdomain}.${domain()}/member?t=${token}`
