import { Anchor } from "~/core/components"
import { AppStoreSVG, GooglePlaySVG, SonatoLogoSVG } from "~/marketing/components/vector-art"

const APP_STORE_URL = "https://apps.apple.com/us/app/sonato-alliance/id1553756187"
const GOOGLE_PLAY_URL = "https://play.google.com/store/apps/details?id=com.sonato.members"

function absoluteUrl(path = "/") {
  return `https://sonato.dev${path}`
}
const privacyPolicyUrl = absoluteUrl("/legal/privacy")

const Footer = ({ brandName, brandLogoImageUrl, backgroundColor }) => (
  <div className="space-y-6 p-8 text-white md:space-y-14 md:p-12" style={{ backgroundColor }}>
    <div className="flex justify-between">
      <div className="flex">
        <div className="flex items-center">
          <img className="max-h-18" alt={brandName} src={brandLogoImageUrl} />
        </div>
        <div className="h-18 ml-8 flex items-center border-l border-white/20 pl-8">
          <SonatoLogoSVG className="w-40" />
        </div>
      </div>
      <div className="ml-20 md:ml-8">
        <div className="flex space-x-3">
          <Anchor
            className="block rounded border border-white px-3 py-2 text-white shadow transition duration-300 hover:scale-[1.02] hover:border-white/60 hover:text-white/60 active:scale-100"
            href={APP_STORE_URL}
          >
            <AppStoreSVG className="w-20 fill-current md:w-32" />
            <span className="sr-only">Download on the App Store</span>
          </Anchor>
          <Anchor
            className="block rounded border border-white px-3 py-2 text-white shadow transition duration-300 hover:scale-[1.02] hover:border-white/60 hover:text-white/60 active:scale-100"
            href={GOOGLE_PLAY_URL}
          >
            <GooglePlaySVG className="w-20 fill-current md:w-32" />
            <span className="sr-only">Get it on Google Play</span>
          </Anchor>
        </div>
      </div>{" "}
    </div>

    <div className="flex items-end justify-between">
      <address className="not-italic">
        245 5th Street, San Francisco, CA 94103
        <br />
        <Anchor
          href="mailto:hello@sonato.com"
          className="transition duration-300 hover:text-white/60"
        >
          hello@sonato.com
        </Anchor>
      </address>
      <div className="flex space-x-1 text-xs font-bold uppercase text-white/40">
        <Anchor
          href={privacyPolicyUrl}
          className="block px-2 pt-2 transition duration-300 hover:text-white"
        >
          Privacy Policy
        </Anchor>
        <div className="pt-2">© 2022</div>
      </div>
    </div>
  </div>
)

export default Footer
