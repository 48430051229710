import clsx from "clsx"
import PropTypes from "prop-types"
import { formatDate } from "~/core/date"

/**
 * A formatted date helper component.
 *
 * ```
 * date       // date-like object (Date, ISO-8601 string, or Unix timestamp in milliseconds)
 * format     // format string (see FORMATS in date.js) or formatter function
 * timeZone   // optional time zone for formatter function
 * className  // optional classes
 * ```
 */
const DateFormatter = ({ date, format = "iso8601", timeZone, className }) => (
  <span title={formatDate(date, "locale")} className={clsx("whitespace-nowrap", className)}>
    {formatDate(date, format, timeZone)}
  </span>
)
DateFormatter.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string, PropTypes.number])
    .isRequired,
  format: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  timeZone: PropTypes.string,
  className: PropTypes.string,
}
export default DateFormatter
