import * as React from "react"
import { Anchor, MailTo } from "~/core/components"
import { AppStoreIcon, GooglePlayIcon } from "~/core/components/icons"
import { useDevice, useViewport } from "~/core/hooks"
import { SonatoLogoSVG } from "~/marketing/components/vector-art"
import * as Routes from "~/routes"

function Footer() {
  const device = useDevice()
  const { width } = useViewport()
  // on mobile, only show device-specific links
  const showIOS = !device.isAndroid
  const showAndroid = !device.isIOS
  const iconsOnly = width < 520 && showIOS && showAndroid

  return (
    <>
      <div className="flex bg-gray-900 p-8 sm:p-16 md:p-24">
        <div className="w-full max-w-3xl space-y-8 sm:flex sm:justify-between sm:space-y-0">
          <section>
            <h4 className="sm:text-normal font-sans text-xs font-bold uppercase text-gold-900 sm:text-sm">
              Learn more
            </h4>
            <p className="mt-4 font-serif text-lg font-semibold text-white sm:mt-6">
              <Anchor className="transition duration-300 hover:text-gray-500" href={Routes.about()}>
                What we build
              </Anchor>
            </p>
            <p className="mt-2 font-serif text-lg font-semibold text-white">
              <Anchor
                className="transition duration-300 hover:text-gray-500"
                href={Routes.contact()}
              >
                Get in touch
              </Anchor>
            </p>
          </section>
          <section>
            <h4 className="sm:text-normal font-sans text-xs font-bold uppercase text-gold-900 sm:text-sm">
              Location
            </h4>
            <p className="mt-4 font-serif text-lg font-semibold text-white sm:mt-6">
              245 5th Street
            </p>
            <p className="mt-2 font-serif text-lg font-semibold text-white">San Francisco, CA</p>
          </section>
          <section>
            <h4 className="sm:text-normal font-sans text-xs font-bold uppercase text-gold-900 sm:text-sm">
              Email us
            </h4>
            <p className="mt-4 font-serif text-lg font-semibold text-white sm:mt-6">
              <MailTo
                className="transition duration-300 hover:text-gray-500"
                email="hello@sonato.com"
              />
            </p>
          </section>
        </div>
      </div>
      <div className="flex justify-between border-t border-gray-700 bg-gray-900 p-8">
        <div className="flex items-center">
          <Anchor className="hidden text-white sm:mr-16 sm:block" href={Routes.homepage()}>
            <span className="sr-only">Sonato</span>
            <SonatoLogoSVG className="w-32" />
          </Anchor>
          <div className="flex space-x-4 sm:space-x-6 md:space-x-10">
            <Anchor
              href={Routes.faq()}
              className="block text-gray-500 transition duration-300 hover:text-gray-300"
            >
              FAQ
            </Anchor>
            <Anchor
              href={Routes.privacyPolicy()}
              className="block text-gray-500 transition duration-300 hover:text-gray-300"
            >
              Privacy Policy
            </Anchor>
            <Anchor
              href={Routes.termsOfUse()}
              className="block text-gray-500 transition duration-300 hover:text-gray-300"
            >
              Terms of Use
            </Anchor>
            <Anchor
              href={Routes.deleteAccount()}
              className="block text-gray-500 transition duration-300 hover:text-gray-300"
            >
              Delete Account
            </Anchor>
          </div>
        </div>
        <div className="flex space-x-6 md:space-x-10">
          {showIOS && (
            <Anchor
              href={Routes.appStoreDownload()}
              className="flex items-center space-x-2 text-white transition duration-300 hover:text-gray-500"
            >
              <AppStoreIcon className="h-5 w-5" />{" "}
              <div className={iconsOnly ? "sr-only" : ""}>iOS App</div>
            </Anchor>
          )}
          {showAndroid && (
            <Anchor
              href={Routes.googlePlayDownload()}
              className="flex items-center space-x-2 text-white transition duration-300 hover:text-gray-500"
            >
              <GooglePlayIcon className="h-5 w-5" />{" "}
              <div className={iconsOnly ? "sr-only" : ""}>Android App</div>
            </Anchor>
          )}
        </div>
      </div>
    </>
  )
}

export default Footer
