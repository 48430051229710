import { Popover } from "@headlessui/react"
import clsx from "clsx"
import { motion } from "framer-motion"
import { useRouter } from "next/router"
import { Anchor } from "~/core/components"
import { BellIcon } from "~/core/components/icons"
import { SonatoLogoSVG } from "~/marketing/components/vector-art"
import { Panel } from ".."
import NotificationsOverlay from "./NotificationsOverlay"

const avatarUrl =
  "https://i.sonato.dev/-kQDBm4bcpGH9DwYcAcEEyzuIJIjjoIhoZVyCb0uTZA/fill/800/800/sm/1/czM6Ly91cGxvYWRzLXNvbmF0by1vcy9pbWFnZXMvdXBsb2Fkcy9zb25hdG8uYWNjb3VudHMudXNlci8yNjY4NTAyOTYyMzQyNDIwNDgvYXZhdGFyLzI5MDMyNjI4MzU4ODQ2NDY0MC1hdmF0YXIuanBn.jpg"

function Header() {
  const { pathname } = useRouter()
  const { open, close, panelProps } = Panel.usePanel()
  const isClubsActive = pathname.startsWith("/clubs")
  const isVisitsActive = pathname.startsWith("/visits")
  const isInboxActive = pathname.startsWith("/inbox")
  const hasNotifications = true
  const bgClass = hasNotifications ? "bg-red-900" : "bg-gold-300"
  const textClass = hasNotifications ? "text-white" : "text-gold-500"

  return (
    <div className="flex justify-center py-6 sm:grid sm:h-24 sm:grid-cols-3 sm:border-b sm:border-gold-300 sm:py-0 md:h-28">
      <div className="flex justify-start sm:pl-4 md:pl-8">
        <Anchor href="/clubs" className="flex items-center px-4">
          <SonatoLogoSVG className="w-32 sm:w-40" />
        </Anchor>
      </div>
      <div className="hidden justify-center font-sans text-sm font-bold uppercase text-gray-700 sm:flex">
        <NavLink href="/" active={isClubsActive}>
          Clubs
        </NavLink>
        <NavLink href="/visits" active={isVisitsActive}>
          Visits
        </NavLink>
        <NavLink href="/inbox" active={isInboxActive}>
          Inbox
        </NavLink>
      </div>
      <div className="hidden justify-end pr-4 sm:flex md:pr-8">
        <Popover className="relative">
          <Popover.Button className="h-full transform-gpu pl-4 pr-1.5 outline-none duration-300 hover:opacity-90">
            <div className={clsx("flex h-8 w-8 items-center justify-center rounded-full", bgClass)}>
              <BellIcon className={clsx("h-4 w-4", textClass)} />
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute right-1 top-14 z-10 sm:right-5 sm:top-[72px] md:top-20 md:right-4">
            <NotificationsOverlay />
          </Popover.Panel>
        </Popover>
        <button onClick={() => open()} className="pl-1.5 pr-4 duration-300 hover:opacity-90">
          <img
            src={avatarUrl}
            className="h-8 w-8 rounded-full"
            width="32"
            height="32"
            alt="Account"
          />
        </button>
        <Panel {...panelProps}>
          <Panel.Content
            title="Account"
            description="Update your persona information"
            onClose={close}
          >
            <div className="px-4 py-8">Panel details go here</div>
          </Panel.Content>
        </Panel>
      </div>
    </div>
  )
}

function NavLink({ href, active, children }) {
  return (
    <Anchor
      href={href}
      className={clsx(
        "relative flex items-center px-4 outline-none duration-300 hover:text-gold-900/80",
        active && "text-gold-900"
      )}
    >
      {children}
      {active && <ActiveIndicator className="absolute left-1/2 -bottom-1.5" />}
    </Anchor>
  )
}
//

const ActiveIndicator = ({ className }) => (
  <motion.div
    transition={{ type: "spring", bounce: 0.4 }}
    className={className}
    layoutId="active-indicator"
  >
    <svg
      viewBox="0 0 10 10"
      className="tranform h-3 w-3 -translate-x-1/2 fill-transparent stroke-current"
      strokeWidth="2"
    >
      <circle cx="5" cy="5" r="4" />
    </svg>
  </motion.div>
)
export default Header
