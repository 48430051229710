import clsx from "clsx"
import Head from "next/head"
import svgFavicon from "~/core/assets/favicon.svg"
// import fontBasis400 from "~/core/assets/fonts/basis-grotesque-400-normal.woff2"
// import fontBasis700 from "~/core/assets/fonts/basis-grotesque-700-bold.woff2"
// import fontPoyner600 from "~/core/assets/fonts/poynter-oldstyle-display-600-semibold.woff2"
import { useBodyClassName, useDevice, useOrientation } from "~/core/hooks"
import Footer from "./layout/Footer"
import Header from "./layout/Header"

//const PRELOAD_FONTS = [fontPoyner600, fontBasis400, fontBasis700]

function Layout({ children }) {
  useBodyClassName("bg-gold-100 text-gray-900")
  const { isIOS } = useDevice()
  const { changedSinceMount } = useOrientation()
  // workaround: sticky footers break on iOS when the phone is rotated, so disable if orientation changes
  const stickyFooter = !isIOS || !changedSinceMount

  return (
    <>
      <Head>
        <meta name="theme-color" content="#f6f6f3" /> {/* gold100 */}
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="icon" href={svgFavicon.src} type="image/svg+xml" />
        <link rel="icon" href="/favicon.ico" />
        {/* {PRELOAD_FONTS.map((href) => (
          <link
            key={href}
            rel="preload"
            href={href}
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
        ))} */}
      </Head>
      <div className="relative isolate z-10 flex min-h-screen flex-col bg-gold-100 text-base shadow-[0_24px_32px_#000]">
        <header className="fixed inset-x-0 top-0 z-20">
          <Header />
        </header>
        <main className="mt-20 grow">{children}</main>
      </div>
      <footer className={clsx(stickyFooter && "sticky bottom-0")}>
        <Footer />
      </footer>
    </>
  )
}

export default Layout
