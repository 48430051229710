import { useRouter } from "next/router"
import * as React from "react"

function Redirect({ to }) {
  const { isReady, push } = useRouter()
  React.useEffect(() => {
    if (isReady) {
      push(to)
    }
  }, [isReady, push, to])
  return null
}

export default Redirect
