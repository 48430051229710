var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Ds4PwNtPKDT2vYuzCnPE5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"

const isProdDeploy = window.location.hostname.endsWith("sonato.com")
const config = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: isProdDeploy ? "prod" : "stage",
  release: process.env.NEXT_PUBLIC_RELEASE,
  tracesSampleRate: isProdDeploy ? 0.25 : 0,
}
Sentry.init(config)
