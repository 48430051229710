import Image from "next/legacy/image"

function UploadCareImage({
  uuid,
  width,
  height,
  cropAlignment = "center",
  sizes,
  coverPosition,
  alt,
  priority,
}) {
  const src = `${uuid}/-/format/jpeg/-/quality/smart/-/progressive/yes/-/crop/${width}:${height}/${cropAlignment}/`
  return coverPosition ? (
    <Image
      src={src}
      sizes={sizes}
      priority={priority}
      layout="fill"
      objectFit="cover"
      objectPosition={coverPosition}
      loader={uploadCareLoader}
      alt={alt}
    />
  ) : (
    <Image
      src={src}
      sizes={sizes}
      priority={priority}
      width={width}
      height={height}
      layout="responsive"
      loader={uploadCareLoader}
      alt={alt}
    />
  )
}

function uploadCareLoader(params) {
  let { src, width } = params
  if (width > 5000) {
    width = 5000
  }
  return `https://ucarecdn.com/${src}-/resize/${width}x/`
}

export default UploadCareImage
