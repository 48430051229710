import { Dialog, Transition } from "@headlessui/react"
import { useRouter } from "next/router"
import * as React from "react"
import { Anchor } from "~/core/components"

/**
 * Panel component.
 *
 * #### Props
 *
 *     isOpen      // open state
 *     close       // close functon
 *     onDidClose  // after close callback
 *     children    // child elements
 */
const Panel = ({ isOpen, close, onDidClose, children }) => (
  <Transition show={isOpen} as={React.Fragment} afterLeave={onDidClose}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={close}>
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={React.Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={React.Fragment}
            enter="transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="w-screen max-w-2xl">
              <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
)

const PanelContent = ({ title, description, onClose, children }) => (
  <div className="flex-1">
    <div className="bg-gray-50 px-4 py-6 sm:px-6">
      <div className="flex items-start justify-between space-x-3">
        <div className="space-y-1">
          <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
          <Dialog.Description className="text-sm text-gray-500">{description}</Dialog.Description>
        </div>
        <div className="flex h-7 items-center">
          <button
            type="button"
            className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close panel</span>
            <span name="close" className="inline-block h-6 w-6">
              X
            </span>
          </button>
        </div>
      </div>
    </div>
    {children}
  </div>
)
Panel.Content = PanelContent

const PanelLink = ({ className, href = "", id = "new", replace = true, children }) => (
  <Anchor id={`panel-${id}`} className={className} href={`${href}?panel=${id}`} replace={replace}>
    {children}
  </Anchor>
)
Panel.Link = PanelLink

const PanelButton = ({ className, href = "", id = "new", replace = true, children }) => (
  <button id={`panel-${id}`} className={className} href={`${href}?panel=${id}`} replace={replace}>
    {children}
  </button>
)
Panel.Button = PanelButton

/**
 * Manages a panel's state and synchronizes it with the browser URL (this allows deep-linking to open
 * panels).
 *
 * If the `panel` query parameter is present, opens the panel and `panel.id` becomes the
 * passed value (unless it is "new" in which case the `panel.id` is null).
 *
 * Open a panel by calling `open()` or `open(id)`. This replaces the current URL with `?panel=id` and
 * triggers the open behavior. To close a panel, invoke `close()`.
 *
 * #### Panel Object Result
 *
 *     id          // panel content id
 *     open        // opens the panel
 *     close       // closes the panel
 *     panelProps  // spread into Panel component `<Panel {...panelProps}>`
 *
 * @returns Panel Object Result
 */
const usePanel = () => {
  const router = useRouter()
  const [panel, setPanel] = React.useState({ id: null, isOpen: false })
  const close = React.useCallback(() => setPanel((p) => ({ ...p, isOpen: false })), [])
  const open = React.useCallback((id = null) => setPanel({ id, isOpen: true }), [])

  React.useEffect(() => {
    const panelId = router.query.panel
    if (panelId) {
      open(panelId === "new" ? null : panelId)
    }
  }, [open, router.query.panel])

  return {
    id: panel.id,
    open,
    close,
    panelProps: {
      isOpen: panel.isOpen,
      close,
    },
  }
}
Panel.usePanel = usePanel

export default Panel
