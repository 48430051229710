import clsx from "clsx"
import { useRouter } from "next/router"
import * as React from "react"
import { Anchor } from "~/core/components"
import { HamburgerIcon } from "~/core/components/icons"
import { SonatoLogoSVG } from "~/marketing/components/vector-art"
import * as Routes from "~/routes"
import MobileOverlay from "./MobileOverlay"

function Header() {
  const [mobileOverlay, setMobileOverlay] = React.useState(false)

  return (
    <>
      <div className="flex h-20 items-center justify-between border-b border-gold-300 bg-gold-100/95 pl-8">
        <Anchor href={Routes.homepage()}>
          <span className="sr-only">Sonato</span>
          <SonatoLogoSVG className="w-40" />
        </Anchor>
        <div className="hidden justify-center font-sans text-sm font-bold uppercase sm:flex">
          <NavLink href={Routes.homepage()} active={true}>
            Home
          </NavLink>
          <NavLink href={Routes.about()} active={false}>
            What we build
          </NavLink>
          <NavLink href={Routes.contact()} active={false}>
            Get in touch
          </NavLink>
        </div>
        <div className="text-right md:w-40">
          <button
            className="py-6 px-8 outline-none sm:hidden"
            onClick={() => setMobileOverlay(true)}
          >
            <span className="sr-only">Open menu</span>
            <HamburgerIcon className="h-6 w-6" />
          </button>
          <Anchor
            href={Routes.login()}
            className="mr-8 hidden rounded-full border border-gold-900 px-5 py-2 font-sans text-sm font-bold uppercase text-gold-900 transition duration-300 hover:bg-gold-900 hover:text-white sm:inline-block"
          >
            Log in
          </Anchor>
        </div>
      </div>
      <MobileOverlay open={mobileOverlay} onClose={() => setMobileOverlay(false)} />
    </>
  )
}

function NavLink({ href, children }) {
  const { pathname } = useRouter()
  const active = pathname === href
  return (
    <Anchor
      href={href}
      className={clsx(
        "relative flex items-center px-3 outline-none transition duration-300 hover:text-gray-900",
        active ? "text-gray-900" : "text-gray-400"
      )}
    >
      {children}
    </Anchor>
  )
}

export default Header
