import clsx from "clsx"
import { useRouter } from "next/router"
import { Anchor } from "~/core/components"
import {
  AccountMobileIcon,
  BellIcon,
  ClubsMobileIcon,
  InboxMobileIcon,
  VisitsMobileIcon,
} from "~/core/components/icons"

function MobileTabBar({ backgroundColor }) {
  const { pathname } = useRouter()
  const isClubsActive = pathname.startsWith("/clubs")
  const isVisitsActive = pathname.startsWith("/visits")
  const isInboxActive = pathname.startsWith("/inbox")
  const isNotificationsActive = pathname.startsWith("/notifications")
  const isAccountActive = pathname.startsWith("/account")
  const hasNotifications = true

  return (
    <div
      className="grid h-full grid-cols-5 items-center px-2.5 text-center font-sans text-xs font-bold tracking-normal text-white/40"
      style={{ backgroundColor }}
    >
      <TabLink href="/clubs" active={isClubsActive}>
        <ClubsMobileIcon className="h-6 w-6" />
        Clubs
      </TabLink>
      <TabLink href="/visits" active={isVisitsActive}>
        <VisitsMobileIcon className="h-6 w-6" />
        Visits
      </TabLink>
      <TabLink href="/inbox" active={isInboxActive}>
        <InboxMobileIcon className="h-6 w-6" />
        Inbox
      </TabLink>
      <TabLink href="/notifications" active={isNotificationsActive}>
        <BellIcon className="h-6 w-6" />
        Notifications
        {hasNotifications && (
          <div className="absolute top-0 left-1/2 mt-3 ml-2.5 h-2 w-2 rounded-full bg-red-900" />
        )}
      </TabLink>
      <TabLink href="/account" active={isAccountActive}>
        <AccountMobileIcon className="h-6 w-6" />
        Account
      </TabLink>
    </div>
  )
}

const TabLink = ({ href, active, children }) => (
  <Anchor
    href={href}
    className={clsx(
      "relative flex h-full flex-col items-center justify-center transition-colors",
      active && "text-white"
    )}
  >
    {children}
  </Anchor>
)

export default MobileTabBar
