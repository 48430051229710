import clsx from "clsx"

/**
 * JSON dump component.
 */
function JSONDump({ className = "max-h-64", json }) {
  const serialized = JSON.stringify(json, null, 2)
  return (
    <pre
      className={clsx(className, "my-2 overflow-auto rounded bg-gray-800 px-3 py-2 text-gray-300")}
    >
      <code>{serialized}</code>
    </pre>
  )
}

export default JSONDump
